import { createApp } from 'vue'
import App from "./App";
import router from './router'
import element, { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css'


// Vue.config.productionTip = false
// Vue.prototype.$axios = axios

// createApp(App).mount('#app')

const app = createApp(App)
app.config.globalProperties.$message= ElMessage
app.use(router).use(element).mount('#app')
