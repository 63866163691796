
import { createRouter, createWebHistory } from "vue-router";



const routes = [
  { path: '/', name:'Home',  component:()=>import('./MyHome.vue') },
  { path: '/about', name:'About', component:()=>import('./MyAbout.vue') },
  { path: '/djk/privacy', name:'PatientPrivay', component:()=>import('./PatientPrivay.vue') },
  { path: '/article/:articleId', name:'Article', component:()=>import('./ArticleDetail.vue') },
  { path: '/signup/phone', name:'SignupPhone', component:()=>import('./signup/SignupPhone.vue') },
  { path: '/signup/password', name:'SignupPassword', component:()=>import('./signup/SignupPassword.vue') },
  { path: '/signup/success', name:'SignupSuccess', component:()=>import('./signup/SignupSuccess.vue') },
  // { path: '/qrcode/20220920', name:'QRCode', component:()=>import('./QRCode.vue') },
  { path: '/qrcode/:date', name:'QRCode', component:()=>import('./QRCode.vue') }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router

